import React from 'react';
import img_aragonenlared from '../assets/img/foto-premio-aragonenlared.jpg';
import img_taller from '../assets/img/numericco_curso0016.jpg';
import LazyLoad from 'react-lazy-load';


function Experiencia() {
  return (

    <div>
      <div class="container">
          <div class="contenido">
              <div class="container ng-scope">
                  <div class="row">
                      
                      <div class="col-md-7 col-sm-7 col-xs-12 ">

                          <div class="contenido__text">
                                <p>
                                Tras varias experiencias como desarrollador web en diferentes empresas del sector, actualmente soy Co-fundador y director técnico en la&nbsp;
                              <a href="https://www.numericco.com" rel="noopener noreferrer" target="_blank">Agencia digital creativa Numéricco</a>  en la que desarrollamos soluciones digitales para empresas que quieren impactar.
                            </p>
                            <h2> ¿Qué puedo hacer por ti?</h2>
                            <p>
                                Trabajo para que los proyectos cubran necesidades, sean fáciles de usar, se vean correctamente en todos los dispositivos y sobretodo, que funcionen. Siempre antes analizando y evaluando las necesidades y requisitos de cada cliente/proyecto:
                            </p>
                            <p>
                            - Proyectos web con HTML5, CSS3, Preprocesadores Sass y Frameworks JavaScript como ReactJS.<br />
                            - Webs corporativas a medida.<br />
                            - Desarrollo Ecommerce: WooCommerce y PrestaShop. <br />
                            - Desarrollo web a medida: frameworks PHP CakePHP, Symfony ó Laravel y con bases de datos MySQL.<br />
                            - Arquitectura de Información, Análisis de requisitos, Usabilidad web y Experiencia de usuario (UX).
                            </p>
                            <h2>Eventos / Ponencias</h2>
                            
                            <p class="contenido__item">
                                <strong>WordCamp Zaragoza 2018</strong><br />
                                "Arquitectura frontend para proyectos con preprocesadores CSS"  <i>Zaragoza. Enero 2018</i>
                            </p>

                            <p class="contenido__item">
                                <strong>Congreso Web 2018</strong><br />
                                "Identifica tus objetivos y crea una tienda online en WordPress paso a paso con éxito"  <i>Zaragoza. Marzo 2018</i>
                            </p>

                            <p class="contenido__item">
                                <strong>Feria Tienda Virtuales Aragón</strong><br />
                                "Crea tu tienda online con WooCommerce"  <i>Huesca. Abril 2018</i>
                                <br />
                                "¿Cual es el gestor de ecommerce que más se ajusta a las necesidades de mi empresa?"  <i>Huesca. Abril 2019</i>
                            </p>
                        
                            
                          </div>
                           
                      </div>

                      <div class="col-md-5 col-sm-5 col-xs-12 sidebar">
                      <LazyLoad offset={100}>
                        <img src={img_aragonenlared} alt="Foto Mac escritorio" class="cover img-responsive ng-scope" />
                      </LazyLoad>
                      <LazyLoad offset={100}>
                        <img src={img_taller} alt="Foto Mac escritorio" class="cover img-responsive ng-scope" />
                      </LazyLoad>

                      </div>
                      
                  </div>
              </div>
          </div>
      </div>
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-12" itemscope itemtype="http://schema.org/Person">
                        <p itemprop="name">
                            <span>&copy; 2020. Alberto Portolés Coscojuela</span>
                            <a itemprop="telephone" href="tel:678650579">678 650 579</a>
                            <a itemprop="email" href="mailto:alberto@albertoportoles.es">alberto@albertoportoles.es</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  );
}

export default Experiencia;


