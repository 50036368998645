import React from 'react';

  
function Header() {
  return (
    <header class="App-header">
        <div class="header-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <p class="h3">AP</p>
                    </div>
                    <div class="col-md-6 col-sm-6 segundo">
                        <p>
                            <a href="tel:678650579"> 678 650 579</a>
                            <a href="mailto:alberto@albertoportoles.es">alberto@albertoportoles.es</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </header>
  );
}

export default Header;


