import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import Content from './Content';
  import Experiencia from './Experiencia';
  import NotFound from './NotFound';
  import ProfilePhoto from '../assets/img/alberto-numericco.jpg';


  
function Nav() {
  return (  
    <Router>
        <div class="header">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <img alt="Alberto Portolés Numéricco" class="img-rounded" src={ProfilePhoto} />
                        <p class="h1">Alberto Portolés</p>
                        <h1 class="h2">Desarrollo web FullStack</h1>
                        <p>
                        <span>Soy Ingeniero Técnico en Informática de Gestión. </span><br />
                        Desarrollo soluciones digitales en <a href="https://www.numericco.com" target="_blank" rel="noopener noreferrer">Numericco</a>
                        </p>
                        <nav>
                        <ul>
                          <li>
                            <Link to="/">
                              <p class="h3">Inicio</p>
                              </Link>
                          </li>
                          <li>
                            <Link to="/experiencia">
                            <p class="h3">EXPERIENCIA</p>
                            </Link>
                          </li>
                        </ul>
                        <Switch>
                          <Route exact path="/"  component={Content} />
                          <Route path="/experiencia" component={Experiencia} />
                          <Route component={NotFound} />
                        </Switch>
                      </nav>
                    </div>
                </div>
            </div>
        </div>
    </Router>
  );
}

export default Nav;


