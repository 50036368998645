import React from 'react';
import MacPhoto from '../assets/img/foto-mac.jpg';
import MyPhoto from '../assets/img/alberto-numericco-oficina.jpg';
import LazyLoad from 'react-lazy-load';


function Content() {
  return (
      <div>

      <div class="container">
          <div class="contenido">
              <LazyLoad offset={100}>
                  <img src={MacPhoto} alt="Foto Mac escritorio" class="cover img-responsive ng-scope" />
              </LazyLoad>
              <div class="container ng-scope">
                  <div class="row">
                      <div class="col-md-5 col-sm-5 col-xs-12 sidebar">
                        <h2 class="text__title">Ingeniero técnico en informática de gestión y desarrollador digital.</h2>
                        <h3>Proyectos online. </h3>
                        <h3>Desarrollo.</h3>
                        <h3>Interacción.</h3>
                        <h3>Usabilidad.</h3>
                            <LazyLoad offset={100}>
                                <img alt="Alberto Portolés" src={MyPhoto} class="img-responsive" />
                            </LazyLoad>
                          
                      </div>
                      <div class="col-md-7 col-sm-7 col-xs-12 ">

                          <p class="h3 text__minititle">¿Quién soy?</p>

                          <div class="contenido__text">
                                <p>
                                Natural de Barbastro (Huesca). Estudio Ingeniería Técnica en Informática de Gestión en la Universitat de Lleida y me especializo en IPO (Interacción Persona-Ordenador). Tras acabar el último curso, enfoco mi futuro a todo lo relacionado con el desarrollo digital y el entorno online. 
                                </p>
                                <h2>Desarrollo proyectos web desde 2010. He trabajado en más de 50 proyectos digitales, tanto con CMS como desarrollo a medida.</h2>
                                <p>
                                Me gusta trabajar el diseño de interacción y la usabilidad de los proyectos que desarrollo. También en la parte más estratégica y analítica de los negocios digitales.  
                                </p>
                          </div>

                          <p class="h3 text__minititle">¿Qué Hago?</p>
                          <div class="contenido__text">
                                <p>
                                Soy desarrollador web FullStack. Desarrollo tanto el front como el back de los proyectos. Desarrollo sitios corporativos y e-commerce en distintas plataformas como PrestaShop ó WooCommerce.
                                </p>
                                <h2>
                                    He trabajado en todas las áreas por las que pasa un proyecto web. Desde el análisis de requisitos, el prototipado de interfaces y la UX hasta la programación web. 
                                </h2>
                                <p>
                                    Mi área de influencia principal es el desarrollo web aunque intento adentrarme en otras áreas que también son influyentes para mí como es el análisis de requisitos y el diseño de interacción.<br />
                                    Pongo mucho incapié en que los proyectos en los que trabajo sean fáciles de usar y sobretodo,
                                    cubran con las necesidades de los usuarios que interactuan con ellos.
                              </p>
                          </div>

                          <p class="h3 text__minititle">Puedes escribirme por:</p>
                          <div class="contenido__text">
                                <p>
                                    <a href="https://twitter.com/albertoportoles" target="_blank" rel="noopener noreferrer">
                                    Twitter
                                    </a>
                                    <br />
                                    <a href="https://www.linkedin.com/profile/view?id=AAMAAArQgM4BILBK-mN3aJoGfK-06_gD6338wcs&trk=hp-identity-photo" rel="noopener noreferrer" target="_blank">
                                    Linkedin
                                    </a>
                                    <br />
                                    <a href="mailto:alberto@albertoportoles.es">
                                    Email
                                    </a>
                                </p>
                            </div>

                      </div>
                  </div>
              </div>
          </div>
      </div>
      <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-12" itemscope itemtype="http://schema.org/Person">
                    <p itemprop="name">
                        <span>&copy; 2020. Alberto Portolés Coscojuela</span>
                        <a itemprop="telephone" href="tel:678650579">678 650 579</a>
                        <a itemprop="email" href="mailto:alberto@albertoportoles.es">alberto@albertoportoles.es</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
    </div>
  );
}

export default Content;


