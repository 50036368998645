import React from 'react';
import Header from './Header';
import Nav from './Nav';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Nav />
      </main>
    
    </div>
  );
}

export default App;
