import React from 'react';


function NotFound() {
    return (
      <div>
        <div>
          <h1>Lo sentimos, esta página no existe.</h1>
        </div>
      </div>
    );
  }

  export default NotFound;
